import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../Context/auth";

function Logout() {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
    document.location.reload();
  }, [logout]);

  return <Navigate to="/login" />;
}

export default Logout;
