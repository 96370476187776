import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const CURRENCY_FORMATTER = new Intl.NumberFormat("pt-BR", {
  currency: "BRL",
  style: "currency",
});

export function formatCurrency(number) {
  return CURRENCY_FORMATTER.format(number);
}

// creates a query string from query object
export function createQueryString(queryObject = {}) {
  let queryString = Object.keys(queryObject)
    .filter(
      (key) =>
        queryObject[key] &&
        !(Array.isArray(queryObject[key]) && !queryObject[key].length)
    )
    .map((key) => {
      return Array.isArray(queryObject[key])
        ? queryObject[key]
            .map(
              (item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`
            )
            .join("&")
        : `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`;
    })
    .join("&");
  return queryString ? `?${queryString}` : "";
}

// turns query string back into an object
export function queryStringToObject(queryString = "", options = {}) {
  let queryObject = {};
  queryString &&
    decodeURIComponent(queryString.replace("?", ""))
      .split("&")
      .map((itemString) => {
        let [itemKey, itemValue] = itemString.split("=");
        if (options.hasOwnProperty(itemKey)) {
          if (!queryObject[itemKey] && Array.isArray(options[itemKey])) {
            queryObject[itemKey] = [];
          }
          Array.isArray(options[itemKey])
            ? queryObject[itemKey].push(itemValue)
            : (queryObject[itemKey] =
                typeof options[itemKey] === "number"
                  ? parseInt(itemValue)
                  : itemValue);
        }
      });
  return queryObject;
}

export const formataCelular = (value) => {
  return (
    value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1") || ""
  );
};

export const formataTelefone = (value) => {
  return (
    value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1") || ""
  );
};

export function apenasNumeros(valor) {
  const str = valor || "";
  return str.toString().replace(/\D+/g, "");
}

export const formataCpfCnpj = (value) => {
  //pega apenas os numeros
  let resultado = value.replace(/\D/g, "");

  if (resultado.length > 11) {
    //se for cnpj
    let cnpj = `${resultado.substr(0, 2)}.${resultado.substr(
      2,
      3
    )}.${resultado.substr(5, 3)}/`;
    if (resultado.length > 12)
      cnpj += `${resultado.substr(8, 4)}-${resultado.substr(12, 2)}`;
    else cnpj += resultado.substr(8);

    resultado = cnpj;
  } else {
    // se for cpf
    let cpf = "";
    let parts = Math.ceil(resultado.length / 3);
    for (let i = 0; i < parts; i++) {
      if (i === 3) {
        cpf += `-${resultado.substr(i * 3)}`;
        break;
      }
      cpf += `${i !== 0 ? "." : ""}${resultado.substr(i * 3, 3)}`;
    }

    resultado = cpf;
  }

  return resultado;
};

export function formatAsPercentage(num) {
  return new Intl.NumberFormat("default", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num / 100);
}

export function isMobileDevice() {
  const userAgent = navigator.userAgent.toLowerCase();
  const mobileKeywords = [
    "iphone",
    "android",
    "ipad",
    "ipod",
    "blackberry",
    "windows phone",
  ];
  return mobileKeywords.some((keyword) => userAgent.includes(keyword));
}

export function mostraErro(texto, tipo = "error") {
  const Alerta = withReactContent(Swal);
  return Alerta.fire({
    title: <p>Erro</p>,
    text: texto,
    icon: tipo,
  });
}
