import axios from "axios";
import fileDownload from "js-file-download";
import fileReader from "./fileReader";
import { apenasNumeros, mostraErro } from "../Utils/helpers";

const API_URL =
  process.env.REACT_APP_API_URL ||
  "https://fiscalfacil.com/ServidorCadastros/v1";

export const LOGIN_URL = API_URL.concat("/portalcontador/login");
export const LOGIN_DEMO = API_URL.concat("/portalcontador/demo");
export const USUARIO_PARAMETROS = API_URL.concat(
  "/portalcontador/usuario/parametros"
);
export const CONSULTA = API_URL.concat("/portalcontador/consulta");
export const CONSULTA_TRIBUTACAO = API_URL.concat("/portalcontador/tributacao");
export const PRODUTOS = API_URL.concat("/portalcontador/produtos");
export const BAIXAR_NOTAS = API_URL.concat("/portalcontador/baixarNotas");
export const SPED_FISCAL = API_URL.concat("/portalcontador/spedfiscal");
export const GETXML = API_URL.concat("/portalcontador/xml/");
export const VISUALIZAR = API_URL.concat("/portalcontador/visualizar/");
export const INSERIRLOG = API_URL.concat("/portalcontador/log");
export const INSERIRCERTIFICADO = API_URL.concat("/portalcontador/certificado");

const api = axios.create({
  baseURL: API_URL,
});

export function getDtIni() {
  var my_date = new Date().setDate(new Date().getMonth() - 1);
  return new Date(
    new Date(my_date).getFullYear(),
    new Date(my_date).getMonth(),
    1
  );
}

export function getDtFim() {
  var my_date = new Date().setDate(new Date().getMonth() - 1);
  return new Date(
    new Date(my_date).getFullYear(),
    new Date(my_date).getMonth() + 1,
    0
  );
}

interface Certificado {
  [key: string]: any;
}

export const useApi = () => ({
  loginCertificado: async (usuario: string) => {
    const response = await api.post(LOGIN_URL, { usuario });
    return response.data;
  },
  login: async (usuario: string, senha: string) => {
    const response = await api.post(LOGIN_DEMO, { usuario, senha });
    return response.data;
  },
  usuarioAtualizaParametros: async (usuario: string) => {
    const params = {
      nr_cpfcnpj: apenasNumeros(usuario),
    };
    const response = await api.get(USUARIO_PARAMETROS, { params });
    return response.data;
  },
  consultaNotas: async (
    CNPJ_REMETENTE: string,
    DT_EMISSAOINI: Date,
    DT_EMISSAOFIM: Date,
    DS_TIPO: string,
    NR_DOCFISCALINI: number,
    NR_DOCFISCALFIM: number,
    VL_DOCFISCALINI: number,
    VL_DOCFISCALFIM: number,
    DS_CHAVE: string,
    NR_CNPJDESTINATARIO: string,
    DS_EMITIDAREC: string,
    DS_FILIAL: string
  ) => {
    const params = {
      cnpj_remetente: apenasNumeros(CNPJ_REMETENTE),
      dt_emissaoini: DT_EMISSAOINI,
      dt_emissaofim: DT_EMISSAOFIM,
      ds_tipo: DS_TIPO,
      nr_docfiscalini: NR_DOCFISCALINI,
      nr_docfiscalfim: NR_DOCFISCALFIM,
      vl_docfiscalini: VL_DOCFISCALINI,
      vl_docfiscalfim: VL_DOCFISCALFIM,
      ds_chave: DS_CHAVE,
      nr_cnpjdestinatario: NR_CNPJDESTINATARIO,
      ds_emitidarec: DS_EMITIDAREC,
      ds_filial: DS_FILIAL,
    };
    const response = await api.get(CONSULTA, { params });
    return response.data;
  },
  consultaTributacao: async (
    estado: string,
    codbarras: string,
    ncm: string,
    nome: string
  ) => {
    const params = {
      ds_uf: estado,
      ds_codbarras: codbarras,
      cd_ncm: ncm,
      nm_prd: nome,
    };
    const response = await api.get(CONSULTA_TRIBUTACAO, { params });
    return response.data;
  },
  consultaProdutos: async (usuario: string, sistema: string) => {
    const params = {
      nr_cpfcnpj: apenasNumeros(usuario),
      nm_sistema: sistema,
    };
    const response = await api.get(PRODUTOS, { params });
    return response.data;
  },
  salvarProdutos: async (produtos: [], usuario: string, sistema: string) => {
    const params = {
      nr_cpfcnpj: apenasNumeros(usuario),
      nm_sistema: sistema,
    };
    const response = await api.put(PRODUTOS, produtos, { params });
    return response.data;
  },
  getXml: async (ds_chave: string) => {
    const response = await api.get(GETXML + ds_chave);
    return response.data;
  },
  visualizarNota: async (ds_chave: string) => {
    const response = await api.get(VISUALIZAR + ds_chave, {
      responseType: "blob",
    });
    // Create a Blob from the response data
    const pdfBlob = new Blob([response.data], { type: "application/pdf" });

    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(pdfBlob);

    return url;
  },
  downloadPDF: async (ds_chave: string) => {
    await api
      .get(VISUALIZAR + ds_chave, {
        responseType: "blob",
      })
      .then((res) => {
        const contentDisposition = res.headers["content-disposition"];
        let fileName = "file.pdf";
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch?.length === 2) fileName = fileNameMatch[1];
        }
        fileDownload(res.data, fileName);
        return res.data;
      })
      .catch(async (error) => {
        if (error.response) {
          try {
            const { data } = error.response;
            // Read file
            const file = fileReader(data);
            // Parse content and retrieve 'message'
            const { message } = JSON.parse(JSON.parse(await file) ?? "{}");
            mostraErro(message, "error");
          } catch (readError) {
            // Show a dummy error if sth goes wrong while retrieving 'message'
            mostraErro("Algo deu errado ao baixar este arquivo");
            return error;
          }
        } else {
          mostraErro("Algo deu errado ao baixar este arquivo");
          return error;
        }
        return null;
      });
  },
  baixarSped: async (usuario: string, sistema: string, data: []) => {
    const params = {
      nr_cpfcnpj: apenasNumeros(usuario),
      nm_sistema: sistema,
    };
    const response = await api.post(SPED_FISCAL, data, {
      params,
      headers: {
        "Content-Type": "application/json",
      },
      //validateStatus: (s) => s <= 500,
      // responseType: "blob",
      //responseType: "blob",
    });
    return response;
  },
  baixarNotas: async (
    cpfcnpj: string,
    tipo: string,
    notas: string,
    ds_emitidarec: string
  ) => {
    const params = {
      cpfcnpj: apenasNumeros(cpfcnpj),
      tipo: tipo,
      notas: notas,
      ds_emitidarec: ds_emitidarec,
    };
    await api
      .post(BAIXAR_NOTAS, params, {
        responseType: "blob",
      })
      .then((res) => {
        const contentDisposition = res.headers["content-disposition"];
        let fileName = "file.zip";
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch?.length === 2) fileName = fileNameMatch[1];
        }
        fileDownload(res.data, fileName);
        return null;
      })
      .catch(async (error) => {
        if (error.response) {
          try {
            const { data } = error.response;
            // Read file
            const file = fileReader(data);
            // Parse content and retrieve 'message'
            const { message } = JSON.parse(JSON.parse(await file) ?? "{}");
            mostraErro(message);
            return message;
          } catch (readError) {
            // Show a dummy error if sth goes wrong while retrieving 'message'
            mostraErro("Algo deu errado ao baixar este arquivo");
            return error;
          }
        } else {
          mostraErro("Algo deu errado ao baixar este arquivo");
          return error;
        }
      });
  },
  inserirLog: (usuario: string, nome: string) => {
    const params = {
      nr_cpfcnpj: apenasNumeros(usuario),
      nm_empresa: apenasNumeros(nome),
    };

    api
      .get(INSERIRLOG, { params })
      .then((res) => {
        return null;
      })
      .catch((error) => {
        console.error(error);
      });
    return null;
  },
  inserirCertificado: async (certificado: Certificado = {}) => {
    await api
      .post(INSERIRCERTIFICADO, certificado)
      .then((res) => {
        return null;
      })
      .catch((error) => {
        console.error(error);
      });
    return null;
  },
});
