import { MenuItem } from "./MenuItem";
import { MenuInnerWithSub } from "./MenuInnerWithSub";
import { useAuth } from "../../../../../app/Context/auth";

export function MenuInner() {
  const { usuario } = useAuth();
  return (
    <>
      <MenuInnerWithSub
        title="Listagem de notas"
        to="/notas"
        menuPlacement="bottom-start"
        menuTrigger="{default:'click', lg: 'hover'}"
      >
        <MenuItem to="/notas/nfe" title="NFe" hasBullet={true} />
        <MenuItem to="/notas/nfce" title="NFCe" hasBullet={true} />
      </MenuInnerWithSub>

      <MenuItem title="Produtos" to="/cadastros/produtos" />

      <MenuItem title="Consulta Tributária" to="/consultas/tributacao" />

      {usuario.sistema === "SCA" && (
        <MenuInnerWithSub
          title="Arquivo Sped"
          to="/sped"
          menuPlacement="bottom-start"
          menuTrigger="{default:'click', lg: 'hover'}"
        >
          <MenuItem to="/sped/fiscal" title="Sped Fiscal" hasBullet={true} />
          {/*
          <MenuItem
            to="/sped/contribuicoes"
            title="Sped Contribuições"
            hasBullet={true}
          />
         */}
        </MenuInnerWithSub>
      )}

      {/*
      <MenuItem title='Inicio' to='/inicio' />
      <MenuInnerWithSub
        title='Listagem de notas'
        to='/notas'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem to='/notas/nfe' title='NFe' hasBullet={true} />
        <MenuItem to='/notas/nfce' title='NFCe' hasBullet={true} />
      </MenuInnerWithSub>
      */}
    </>
  );
}
