/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useAuth } from "../../../../app/Context/auth";

const HeaderUserMenu: FC = () => {
  const { usuario, logout } = useAuth();

  const pegarPrimeiroValor = (valueString: string) => {
    if (!valueString) return "";
    // Usando regex para dividir a string por ; , / |
    const values = valueString.split(/[;,\/|]/);
    return values[0].trim();
  };

  const email = pegarPrimeiroValor(usuario?.email);
  const telefone = pegarPrimeiroValor(usuario?.telefone);

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5"></div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {usuario?.nome}
            </div>
            <div className="fw-bolder d-flex align-items-center fs-5">
              {usuario?.cpfcnpj}
            </div>
            <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
              {email}
            </a>
            <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
              {telefone}
            </a>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5">
        <a
          href="https://www.consultoriasolucao.com"
          target="_blank"
          className="menu-link px-5"
          rel="noreferrer"
        >
          Sistema - {usuario?.sistema}
        </a>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5">
        <a onClick={logout} className="menu-link px-5">
          Sair
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
