import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import React from "react";
import RotasPrivadas from "./RotasPrivadas";
import { useAuth } from "../app/Context/auth";
import Demo from "../app/Paginas/Login/demo";
import Login from "../app/Paginas/Login/login";
import Logout from "../app/Paginas/Login/logout";
import PaginaErros from "../app/Paginas/Erros/PaginaErros";
import App from "../app";

/**
 * Base URL of the website
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

function Rotas() {
  const { usuario } = useAuth();

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="erro/*" element={<PaginaErros />} />
          <Route path="logout" element={<Logout />} />
          <Route path="demo" element={<Demo />} />
          {usuario ? (
            <>
              <Route path="/*" element={<RotasPrivadas />} />
              <Route index element={<Navigate to="/notas/nfe" />} />
            </>
          ) : (
            <>
              <Route path="login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
    /**
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route
        path='*'
        element={
          <Private>
            <MasterLayout>
              <RotasPrivadas />
            </MasterLayout>
          </Private>
        }
      />

      <Route exact path='/logout' element={<Logout />} />
    </Routes> */
  );
}

export default Rotas;
