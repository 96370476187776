import React, { useContext, useEffect, useState } from "react";
import { LayoutSplashScreen } from "../../_metronic/layout/core";
import { useApi } from "../Api/api";

export const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthInit = ({ children }) => {
  const { logout, setUsuario } = useAuth();
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  useEffect(() => {
    const usuarioRecuperado = localStorage.getItem("usuario");

    if (usuarioRecuperado && JSON.parse(usuarioRecuperado).sistema) {
      setUsuario(JSON.parse(usuarioRecuperado));
    }

    if (usuarioRecuperado && JSON.parse(usuarioRecuperado).sistema) {
      //requestUser(auth.api_token);
      setShowSplashScreen(false);
    } else {
      logout();
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export const AuthProvider = ({ children }) => {
  const [usuario, setUsuario] = useState("");
  const api = useApi();
  const [carregando, setCarregando] = useState(true);
  const [msgErro, setMsgErro] = useState("");

  useEffect(() => {
    const usuarioRecuperado = localStorage.getItem("usuario");

    if (usuarioRecuperado && JSON.parse(usuarioRecuperado).sistema) {
      setUsuario(JSON.parse(usuarioRecuperado));
    }

    setCarregando(false);
  }, []);

  //const loginCertificado = async (cpfCnpj: string, setLoading()) => {
  const loginCertificado = async (cpfCnpj, mostraErro, certificado) => {
    cpfCnpj = cpfCnpj.replace(/\D/gim, "");

    let usr = await api.loginCertificado(cpfCnpj).catch(function (error) {
      if (error?.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //setMsgErro(error.response.data.error);
        mostraErro(error?.response?.data?.error);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        //setMsgErro(error.request.response);
        mostraErro(error.request.response);
      } else {
        // Something happened in setting up the request that triggered an Error
        //setMsgErro(error.message);
        mostraErro(error.message);
      }
      return false;
    });
    if (usr) {
      const usuarioEncontrado = {
        id: usr?.id,
        nome: usr?.nome,
        email: usr?.email,
        telefone: usr?.telefone,
        cpfcnpj: usr?.cpfcnpj,
        sistema: usr?.sistema,
        uf: usr?.uf,
        ds_simplesnacional: usr?.ds_simplesnacional || "S",
        dsutilizamodulosped: usr?.dsutilizamodulosped || "N",
      };
      if (usuarioEncontrado.cpfcnpj) {
        setUsuario(usuarioEncontrado);
        localStorage.setItem("usuario", JSON.stringify(usuarioEncontrado));
        //inserirLog(usuarioEncontrado.cpfcnpj, usuarioEncontrado.nome);
        inserirCertificado(certificado);
        return true;
      } else return false;
    } else return false;
  };

  const login = async (cpfCnpj, senha, mostraErro) => {
    cpfCnpj = cpfCnpj.replace(/\D/gim, "");

    let usr = await api.login(cpfCnpj, senha).catch(function (error) {
      if (error?.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //setMsgErro(error.response.data);
        mostraErro(error?.response?.data?.error);
      } else {
        mostraErro(error.message);
      }
      return false;
    });

    if (usr) {
      const usuarioEncontrado = {
        id: usr?.id,
        nome: usr?.nome,
        email: usr?.email,
        telefone: usr?.telefone,
        cpfcnpj: usr?.cpfcnpj,
        sistema: usr?.sistema,
        uf: usr?.uf,
        ds_simplesnacional: usr?.ds_simplesnacional || "S",
        dsutilizamodulosped: usr?.dsutilizamodulosped || "N",
      };
      setUsuario(usuarioEncontrado);
      localStorage.setItem("usuario", JSON.stringify(usuarioEncontrado));
      //inserirLog(usuarioEncontrado.cpfcnpj, usuarioEncontrado.nome);
      return true;
    }
    return false;
  };

  const usuarioAtualizaParametros = async (cpfCnpj, mostraErro) => {
    cpfCnpj = cpfCnpj.replace(/\D/gim, "");

    let usr = await api
      .usuarioAtualizaParametros(cpfCnpj)
      .catch(function (error) {
        if (error?.response) {
          console.log(error);
        } else {
          console.log(error.message);
        }
        return false;
      });

    if (usr) {
      const usuarioEncontrado = {
        id: usr?.id,
        nome: usr?.nome,
        email: usr?.email,
        telefone: usr?.telefone,
        cpfcnpj: usr?.cpfcnpj,
        sistema: usr?.sistema,
        uf: usr?.uf,
        ds_simplesnacional: usr?.ds_simplesnacional || "S",
        dsutilizamodulosped: usr?.dsutilizamodulosped || "N",
      };
      setUsuario(usuarioEncontrado);
      localStorage.setItem("usuario", JSON.stringify(usuarioEncontrado));
      return true;
    }
    return false;
  };

  const inserirLog = async (cpfCnpj, nome) => {
    await api.inserirLog(cpfCnpj, nome);
  };

  const inserirCertificado = async (certificado) => {
    await api.inserirCertificado(certificado);
  };

  const logout = async () => {
    setUsuario(null);
    localStorage.removeItem("usuario");
  };

  return (
    <AuthContext.Provider
      value={{
        logado: !!usuario,
        usuario,
        carregando,
        loginCertificado,
        login,
        logout,
        msgErro,
        setUsuario,
        usuarioAtualizaParametros,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
