import React from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { ptBR } from "@mui/material/locale";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

// Apps
import { MetronicI18nProvider } from "./_metronic/i18n/Metronici18n";
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/plugins.scss";
import "./_metronic/assets/sass/style.react.scss";

import "react-datepicker/dist/react-datepicker.css";
//import "react-block-ui/style.css";
import "./app/Components/BlockUI/style.css";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import Rotas from "./Rotas/Rotas";
import { AuthProvider } from "./app/Context/auth";

require("dayjs/locale/pt-br");
dayjs.locale("pt-br"); // use locale globally

let theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
    typography: {
      // In Chinese and Japanese the characters are usually larger,
      // so a smaller fontsize may be appropriate.
      fontSize: 16,
    },
  },
  ptBR
);
theme = responsiveFontSizes(theme);

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
/**
const { PUBLIC_URL } = process.env;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MetronicI18nProvider>
      <App basename={PUBLIC_URL} />
    </MetronicI18nProvider>
  </React.StrictMode>
);
 */

const queryClient = new QueryClient();

const container = document.getElementById("root");
if (container) {
  createRoot(container).render(
    <MetronicI18nProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AuthProvider>
              <Rotas />
            </AuthProvider>
          </LocalizationProvider>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </MetronicI18nProvider>
  );
}
