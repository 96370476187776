/* eslint-disable react/jsx-no-target-blank */
import { SidebarMenuItem } from "./SidebarMenuItem";
import { useAuth } from "../../../../../app/Context/auth";

const SidebarMenuMain = () => {
  const { usuario } = useAuth();

  return (
    <>
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Portal do Contador
          </span>
        </div>
      </div>
      {/*
      <SidebarMenuItem
        to='/inicio'
        icon='/media/icons/duotune/general/gen001.svg'
        title='Inicio'
        fontIcon='bi-app-indicator'
      />
      */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Notas
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to="/notas/nfe"
        icon="/media/icons/duotune/files/fil004.svg"
        title="NFe"
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItem
        to="/notas/nfce"
        icon="/media/icons/duotune/files/fil003.svg"
        title="NFCe"
        fontIcon="bi-app-indicator"
      />

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Cadastros
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to="/cadastros/produtos"
        icon="/media/icons/duotune/ecommerce/ecm010.svg"
        title="Produtos"
        fontIcon="bi-app-indicator"
      />

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Consultas
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to="/consultas/tributacao"
        icon="/media/icons/duotune/ecommerce/ecm010.svg"
        title="Consulta Tributária"
        fontIcon="bi-app-indicator"
      />

      {usuario.sistema === "SCA" && (
        <>
          <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                Arquivo Sped
              </span>
            </div>
          </div>
          <SidebarMenuItem
            to="/sped/fiscal"
            icon="/media/icons/duotune/files/fil004.svg"
            title="Sped Fiscal"
            fontIcon="bi-app-indicator"
          />
          {/*    
          <SidebarMenuItem
            to="/sped/contribuicoes"
            icon="/media/icons/duotune/files/fil003.svg"
            title="Sped Contribuições"
            fontIcon="bi-app-indicator"
          />
         */}
        </>
      )}
    </>
  );
};

export { SidebarMenuMain };
