import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../_metronic/assets/ts/_utils";

function RotasPrivadas() {
  const PaginaNotas = lazy(() => import("../app/Paginas/Notas/index"));
  const PaginaCadastros = lazy(() => import("../app/Paginas/Cadastros/index"));
  const PaginaConsultas = lazy(() => import("../app/Paginas/Consultas/index"));
  const PaginaSPED = lazy(() => import("../app/Paginas/SPED/index"));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Home after success login/registration */}
        <Route path="login" element={<Navigate to="/inicio" />} />
        {/* Pages */}
        {/*   <Route path='inicio' element={<PaginaHome />} /> */}

        <Route
          path="inicio"
          element={
            <SuspensedView>
              <PaginaNotas />
            </SuspensedView>
          }
        />

        {/* Lazy Modules */}
        <Route
          path="notas/*"
          element={
            <SuspensedView>
              <PaginaNotas />
            </SuspensedView>
          }
        />

        <Route
          path="sped/*"
          element={
            <SuspensedView>
              <PaginaSPED />
            </SuspensedView>
          }
        />

        <Route
          path="cadastros/*"
          element={
            <SuspensedView>
              <PaginaCadastros />
            </SuspensedView>
          }
        />

        <Route
          path="consultas/*"
          element={
            <SuspensedView>
              <PaginaConsultas />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/erro/404" />} />
      </Route>
    </Routes>
  );
}

function SuspensedView({ children }) {
  const baseColor = getCSSVariableValue("--kt-primary");
  TopBarProgress.config({
    barColors: {
      0: baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
}
/**
function RotasPrivadasLayout() {
  return <Outlet />;
}

function RotasPrivadas() {
  const PaginaNotas = lazy(() => import('../app/Notas/index'));

  return (
    <Suspense fallback={<FallbackView />}>
      <Routes>
        <Route path='/' element={<RotasPrivadasLayout />}>
          <Route path='notas/*' element={<PaginaNotas />} />
          <Route path='inicio' element={<PaginaHome />} />
          <Route path='/' element={<Navigate to='inicio' />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

 */

export default RotasPrivadas;
