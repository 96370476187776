import LacunaWebPKI from 'web-pki';
import config from '../Config/config.json';

export default class WebPkiService {
  constructor() {
    this._webpki = new LacunaWebPKI();
  }

  async init() {
    return new Promise((resolve) => {
      this._webpki.init({
        restPkiUrl: config.RESTPKI_ENDPOINT,
        ready: resolve,
      });
    });
  }

  async loadCertificates() {
    return new Promise((resolve, reject) =>
      this._webpki
        .listCertificates({
          selectId: 'certificateSelect',
          filter: this._webpki.filters.all(
            this._webpki.filters.isWithinValidity,
            this._webpki.filters.hasPkiBrazilCpf
          ),
          // Function that will be called to get the text that should be displayed for each option:
          selectOptionFormatter: function (cert) {
            var s = cert.subjectName + ' (Emitido por ' + cert.issuerName + ')';
            if (new Date() > cert.validityEnd) {
              s = '[Vencido] ' + s;
            }
            return s;
          },
        })
        .success(resolve)
        .fail(reject)
    );
  }

  async signWithRestPki(thumbprint, token) {
    return new Promise((resolve, reject) =>
      this._webpki
        .signWithRestPki({ token, thumbprint })
        .success(resolve)
        .fail(reject)
    );
  }
}
